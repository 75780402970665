<template>
    <v-app id="inspire">
        <!--侧边栏-->
        <v-navigation-drawer
                v-model="drawer"
                app
        >
            <v-card class="d-flex flex-row justify-space-around" height="60px" elevation="2">
                <v-icon large color="#409EFF">mdi-home-city-outline</v-icon>
                <div class="card_title">闲置租赁场所风险管控系统</div>
            </v-card>
            <!--导航栏-->
            <v-list nav>
                <v-list-item-group color="primary" v-model="selectedItem">
                    <template v-for="item in menuData">
                        <v-list-item v-if="item.children.length===0" @click="menuItemClick(item.index)">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-group v-if="item.children.length!==0" :prepend-icon="item.icon" no-action>
                            <template v-slot:activator>
                                <v-list-item-title>{{item.title}}</v-list-item-title>
                            </template>
                            <v-list-item v-for="c in item.children" color="primary">
                                <v-list-item-content @click="menuItemClick(c.index)">
                                    <v-list-item-title v-text="c.title"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <!--顶部 -->
        <v-app-bar app color="#FFFFFF" height="60px" elevation="2">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <div class="ml-5">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item
                            v-for="(item,index) in breadcrumb"
                            :key="index"
                            :to="{path:item.path}"
                    >{{item.meta.title}}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <v-spacer></v-spacer>
            <!--通知-->
            <v-menu
                    offset-y
                    bottom
                    :close-on-content-click=false
                    max-width="400px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                    >
                        <v-badge
                                bordered
                                dot
                                color="red"
                        >
                            <v-icon>mdi-bell-ring-outline</v-icon>
                        </v-badge>
                    </v-btn>
                </template>
                <v-tabs
                        class="msg_tabs"
                >
                    <v-tab
                            v-for="item in items2"
                            :key="item"
                    >
                        {{ item }}
                    </v-tab>
                    <v-menu
                            offset-y
                            bottom
                            :nudge-left="100"
                            :nudge-top="20"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    class="msg_icon"
                            >
                                <v-icon>mdi-cog-outline</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item
                                    v-for="item in setMsg"
                                    :key="item"
                                    @click="() => {}"
                            >
                                <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-tab-item
                    >
                        <v-list three-line>
                            <template v-for="(item, index) in readMsg">
                                <v-divider
                                        v-if="item.divider"
                                        :key="index"
                                        :inset="item.inset"
                                ></v-divider>

                                <v-list-item
                                        v-else
                                        :key="item.title"
                                >
                                    <v-list-item-avatar>
                                        <v-img :src="item.avatar"></v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title v-html="item.title"></v-list-item-title>
                                        <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-text="item.date"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                >
                                                    mdi-email-check
                                                </v-icon>
                                            </template>
                                            <span>标记为已读</span>
                                        </v-tooltip>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-tab-item>

                    <v-tab-item>
                        <v-list three-line>
                            <template v-for="(item, index) in unReadMsg">
                                <v-subheader
                                        v-if="item.header"
                                        :key="item.header"
                                        v-text="item.header"
                                ></v-subheader>

                                <v-divider
                                        v-else-if="item.divider"
                                        :key="index"
                                        :inset="item.inset"
                                ></v-divider>

                                <v-list-item
                                        v-else
                                        :key="item.title"
                                >
                                    <v-list-item-avatar>
                                        <v-img :src="item.avatar"></v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title v-html="item.title"></v-list-item-title>
                                        <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                >
                                                    mdi-email-check
                                                </v-icon>
                                            </template>
                                            <span>标记为未读</span>
                                        </v-tooltip>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-tab-item>
                </v-tabs>
            </v-menu>
            <!--登录账号-->
            <v-menu
                    offset-y
                    bottom
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                    >
                        <v-icon>mdi-account-circle-outline</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                            v-for="(item,index) in userOption"
                            :key="item"
                            @click="userMsg(index)"
                    >
                        <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <!--内容框-->
        <v-main class="bg_main">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
            <div class="mt-4 mb-2">
                <Footer></Footer>
            </div>
        </v-main>
    </v-app>
</template>

<script>
    import Footer from "@/views/Footer";
    import {getMenu} from "../network/Details/home";

    export default {
        name: "Home",
        components: {Footer},
        data() {
            return {
                breadcrumb: [
                    {
                        meta: {title: '首页'}
                    },
                    {
                        meta: {title: '信息主页'}
                    }
                ],
                userId: window.localStorage.getItem("userId"),
                selectedItem: 0,
                drawer: null,
                //功能栏
                menuData: [],
                //超级管理员
                // menuData: [
                //   {
                //     id: 1,
                //     index: '/primaryHome',
                //     icon: 'el-icon-menu',
                //     title: '首页'
                //   },
                //   {
                //     id: 2,
                //     index: '/personal',
                //     icon: 'el-icon-s-help',
                //     title: '个人中心'
                //   },
                //   {
                //     id: 3,
                //     index: 'userManage', //占位字符串，防止重复导致 同时打开多级菜单
                //     icon: 'el-icon-user-solid',
                //     title: '用户管理',
                //     children: [
                //       {
                //         id: 4,
                //         index: '/userManage_o',
                //         title: '区域管理员'
                //       },
                //       {
                //         id: 5,
                //         index: '/userManage_lease',
                //         title: '区域租赁员'
                //       },
                //     ]
                //   },
                //   {
                //     id: 6,
                //     index: 'houseManage', //占位字符串，防止重复导致 同时打开多级菜单
                //     icon: 'el-icon-s-home',
                //     title: '房屋管理',
                //     children: [
                //       {
                //         id: 7,
                //         index: '/houseManage',
                //         title: '房屋信息'
                //       },
                //       {
                //         id: 8,
                //         index: '/safetyIndexManage',
                //         title: '安全指标'
                //       },
                //     ]
                //   },
                //   {
                //     id: 10,
                //     index: '/postManage',
                //     icon: 'el-icon-message-solid',
                //     title: '公告管理'
                //   },
                // ],
                //普通管理员
                /*      menuData: [
                        {
                          id: 1,
                          index: '/primaryHome',
                          icon: 'el-icon-menu',
                          title: '首页'
                        },
                        {
                          id: 2,
                          index: '/personal',
                          icon: 'el-icon-s-help',
                          title: '个人中心'
                        },
                        {
                          id: 3,
                          index: 'userManage', //占位字符串，防止重复导致 同时打开多级菜单
                          icon: 'el-icon-user-solid',
                          title: '用户管理',
                          children: [
                            {
                              id: 5,
                              index: '/userManage_lease',
                              title: '区域租赁员'
                            },
                          ]
                        },
                        {
                          id: 6,
                          index: 'houseManage', //占位字符串，防止重复导致 同时打开多级菜单
                          icon: 'el-icon-s-home',
                          title: '房屋管理',
                          children: [
                            {
                              id: 7,
                              index: '/houseManage',
                              title: '房屋信息'
                            },
                          ]
                        },
                      ],*/
                //普通用户
                /*      menuData: [
                        {
                          id: 1,
                          index: '/userHome',
                          icon: 'el-icon-menu',
                          title: '首页'
                        },
                        {
                          id: 2,
                          index: '/personal',
                          icon: 'el-icon-s-help',
                          title: '个人中心'
                        },
                        {
                          id: 3,
                          index: '/leaseManage',
                          icon: 'el-icon-s-home',
                          title: '租赁管理',
                        },
                      ],*/
                //  登录用户选项
                userOption: ['账号设置', '退出登录'],
                tab: null,
                //通知
                items2: [
                    '未读通知', '已读通知',
                ],
                setMsg: ['全部已读', '全部删除'],
                //通知内容
                readMsg: [
                    {
                        avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
                        title: '安检员001',
                        subtitle: '沙坪坝区—陈家桥街道已录入完成！',
                        date: '2021.6.20'
                    },
                    {
                        avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
                        title: '安检员002',
                        subtitle: '新耀小区房屋安全信息已录入完成！',
                        date: '2021.6.18'
                    },
                ],
                unReadMsg: [
                    {
                        avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
                        title: '安检员001',
                        subtitle: '沙坪坝区—陈家桥街道已录入完成！',
                        date: '2021.6.20'
                    },
                    {
                        avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
                        title: '安检员002',
                        subtitle: '新耀小区房屋安全信息已录入完成！',
                        date: '2021.6.18'
                    },
                ],
            }
        },
        created() {
            this.menuData=this.$store.getters.getMenu
            if (this.menuData.length===0) {
                getMenu({
                    userID: this.userId
                }).then(res => {
                    if (res.status === 200) {
                        this.menuData=res.data
                        this.$store.commit("setMenu", res.data)
                    }
                })
            }
        },
        methods: {
            menuItemClick(path) {
                this.$router.push(path);
            },
            getRoute() {
                this.breadcrumb = this.$route.matched.filter((item) => {
                    return item.meta;
                });
            },
            userMsg(index) {
                if (index === 0) {
                    this.$router.push('/personal');
                    this.selectedItem = 0;
                }
                if (index === 1) {
                    this.$router.push('/login');
                    window.localStorage.removeItem("token");
                    window.localStorage.removeItem("username");
                }
            },
        },
        watch: {
            $route() {
                this.getRoute();
            },
        },
    }
</script>

<style lang="less" scoped>
    .bg_main {
        background-color: #F5F7F9;
    }

    .search_text {
        width: 300px;
    }

    .card_title {
        font-size: 16px;
        text-align: center;
        line-height: 60px;
        font-weight: bold;
    }

    .msg_tabs {
        position: relative;

        .msg_icon {
            position: absolute;
            right: 6%;
            top: 20%;
        }
    }
</style>
